<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      :title="state.titleText"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <div
        class="bg-white pt-2 d-flex justify-content-center van-hairline--bottom"
      >
        <van-uploader
          v-model="fileList"
          :multiple="true"
          :max-count="4"
          :before-read="beforeRead"
          :after-read="afterRead"
        />
      </div>
      <van-field
        v-model="state.area"
        label="所属区域"
        placeholder="省市区"
        readonly
      >
        <template #extra>
          <span class="text-primary">{{ state.area_code }}</span>
        </template>
      </van-field>
      <van-field
        v-model="state.address"
        label="详细地址"
        placeholder="点击右侧图标选择"
        right-icon="location-o"
        type="textarea"
        rows="2"
        autosize
        readonly
        @click-right-icon="$router.push({ name: 'AddressPicker' })"
      />
      <van-field
        v-model="state.building_type_name"
        readonly
        clickable
        label="建设场所"
        placeholder="点击选择"
        @click="state.showBuildingPicker = true"
      />
      <van-field
        v-model="state.type_name"
        readonly
        clickable
        label="站点类型"
        placeholder="点击选择"
        @click="state.showTypePicker = true"
      />
      <van-field
        v-model="state.name"
        label="站点名称"
        placeholder="例如某某小区/商场充电站"
      />
      <van-field
        v-model="state.parking_num"
        label="车位数量"
        type="number"
        maxlength="4"
        placeholder="不可小于1"
      />
      <van-field
        v-model="state.status_name"
        readonly
        clickable
        label="站点状态"
        placeholder="点击选择"
        @click="state.showStatusPicker = true"
      />
      <van-field
        v-model="state.support_contact"
        label="运营人员"
        placeholder="个人姓名或机构名称"
      />
      <van-field
        v-model="state.support_phone"
        label="运营电话"
        type="number"
        placeholder="手机或座机"
      />
      <van-field
        v-model="state.service_phone"
        label="服务电话"
        type="number"
        placeholder="手机, 座机, 400电话等"
      />
      <div class="py-4 action-button-area text-center">
        <van-button v-if="!canSubmit" type="primary" disabled>{{
          state.buttonText
        }}</van-button>
        <van-button v-else type="primary" @click="submitForm">{{
          state.buttonText
        }}</van-button>
      </div>
    </div>
  </div>
  <van-popup v-model:show="state.showBuildingPicker" position="bottom" round>
    <van-picker
      confirm-button-text="选择"
      :columns="buildingTypeColumn"
      @confirm="chooseBuildingType"
      @cancel="state.showBuildingPicker = false"
    />
  </van-popup>
  <van-popup v-model:show="state.showTypePicker" position="bottom" round>
    <van-picker
      confirm-button-text="选择"
      :columns="stationTypeColumn"
      @confirm="chooseStationType"
      @cancel="state.showTypePicker = false"
    />
  </van-popup>
  <van-popup v-model:show="state.showStatusPicker" position="bottom" round>
    <van-picker
      confirm-button-text="选择"
      :columns="stationStatusColumn"
      @confirm="chooseStatus"
      @cancel="state.showStatusPicker = false"
    />
  </van-popup>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, computed, reactive, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, Toast, Popup, Picker, Uploader } from "vant";
import { saveStation, loadStation } from "@/api/station.service";
import { uploadFile } from "@/api/media.service";
import { loadAddressFromSession } from "@/api/map.service";
import {
  buildingTypes,
  buildingTypeColumn,
  stationTypes,
  stationTypeColumn,
  stationStatus,
  stationStatusColumn,
} from "@/constant/station";
export default {
  name: "StationForm",
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      id: 0, // 有ID为更新, ID=0为添加

      name: "",
      area_code: "",
      area: "",
      address: "",
      support_contact: "",
      support_phone: "",
      service_phone: "",
      longitude: 0,
      latitude: 0,
      parking_num: 0,

      // 于选择器相关的数据
      building_type: 1,
      building_type_name: "居民区",
      type: 1,
      type_name: "公共",
      status: 1,
      status_name: "建设中",

      // 状态数据
      titleText: "加载中",
      buttonText: "加载中",
      loading: true,
      showBuildingPicker: false,
      showTypePicker: false,
      showStatusPicker: false,
    });
    const fileList = ref([]);

    const chooseBuildingType = (value) => {
      state.showBuildingPicker = false;
      state.building_type_name = value[0];
      buildingTypes.map((item) => {
        if (item.name == value[0]) {
          state.building_type = item.id;
          return;
        }
      });
    };
    const chooseStationType = (value) => {
      state.showTypePicker = false;
      state.type_name = value[0];
      stationTypes.map((item) => {
        if (item.name == value[0]) {
          state.type = item.id;
          return;
        }
      });
    };
    const chooseStatus = (value) => {
      state.showStatusPicker = false;
      state.status_name = value[0];
      stationStatus.map((item) => {
        if (item.name == value[0]) {
          state.status = item.id;
          return;
        }
      });
    };

    const canSubmit = computed(() => {
      if (
        !state.name ||
        !state.area ||
        !state.address ||
        !state.building_type ||
        !state.type ||
        !state.status ||
        state.parking_num <= 0 ||
        !state.support_contact ||
        !state.support_phone ||
        !state.service_phone ||
        !state.longitude ||
        !state.latitude
      ) {
        return false;
      }
      return true;
    });

    const loadForm = async (station_id) => {
      try {
        const { result } = await loadStation(station_id);
        for (const key in result) {
          state[key] = result[key];
        }

        // 处理图片显示, 注意: 数据里的URL是无协议头的
        const pictures = JSON.parse(state.pictures);
        if (pictures instanceof Array) {
          for (const i in pictures) {
            const url = `${window.location.protocol}${pictures[i]}`;
            fileList.value.push({
              status: "done",
              message: "Loaded",
              url,
            });
          }
        }

        // 默认选中 Picker 项目
        buildingTypes.map((item, index) => {
          if (item.id == result.building_type) {
            buildingTypeColumn[0].defaultIndex = index;
            state.building_type_name = item.name;
            return;
          }
        });
        stationTypes.map((item, index) => {
          if (item.id == result.type) {
            stationTypeColumn[0].defaultIndex = index;
            state.type_name = item.name;
            return;
          }
        });
        stationStatus.map((item, index) => {
          if (item.id == result.status) {
            stationStatusColumn[0].defaultIndex = index;
            state.status_name = item.name;
            return;
          }
        });

        // 选中地址
        const currentChoiceAddress = loadAddressFromSession();
        if (currentChoiceAddress && currentChoiceAddress.isNewChoice) {
          // 用户新选择了地址则不使用旧的地址(来自数据库)
          state.area = currentChoiceAddress.area;
          state.address = currentChoiceAddress.address;
          state.longitude = currentChoiceAddress.lng;
          state.latitude = currentChoiceAddress.lat;
        } else {
          state.area = result.area;
          state.address = result.address;
          state.latitude = result.latitude;
          state.longitude = result.longitude;
          // 设置已选择地图地址, 当进入地图页面时候才能正确显示当前模型的地址
          const data = {
            isNewChoice: false,
            area: state.area,
            address: state.address,
            lat: state.latitude,
            lng: state.longitude,
          };
          window.sessionStorage.setItem("chooseAddress", JSON.stringify(data));
        }

        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const submitForm = async () => {
      let pictures = [];
      for (const i in fileList.value) {
        // 移除协议头
        const url = fileList.value[i].url;
        pictures.push(url.replace(/https?:\/\//g, "//"));
      }
      try {
        await saveStation({
          id: state.id,
          name: state.name,
          area: state.area,
          address: state.address,
          support_contact: state.support_contact,
          support_phone: state.support_phone,
          service_phone: state.service_phone,
          parking_num: state.parking_num,
          longitude: state.longitude,
          latitude: state.latitude,
          building_type: state.building_type,
          type: state.type,
          status: state.status,
          pictures: JSON.stringify(pictures),
        });
        window.sessionStorage.removeItem("chooseAddress");
        Toast.success("保存成功");
        router.back();
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const beforeRead = (file) => {
      if (file instanceof Array) {
        for (const i in file) {
          if (file[i].type !== "image/jpeg" && file[i].type !== "image/png") {
            Toast("请上传 JPG 或 PNG 格式图片");
            return false;
          }
        }
      } else {
        if (file.type !== "image/jpeg" && file.type !== "image/png") {
          Toast("请上传 JPG 或 PNG 格式图片");
          return false;
        }
      }
      return true;
    };

    const afterRead = (file) => {
      if (file instanceof Array) {
        for (const i in file) {
          processUploadImage(file[i]);
        }
      } else {
        processUploadImage(file);
      }
    };

    const processUploadImage = async (file) => {
      file.status = "uploading";
      file.message = "上传中...";
      try {
        let formData = new FormData();
        formData.append("related_type", "station");
        formData.append("related_id", state.id);
        formData.append("file", file.file);
        const { result } = await uploadFile(formData);
        file.status = "done";
        file.message = "上传成功";
        file.url = result.url;
      } catch (error) {
        file.status = "failed";
        file.message = "上传失败";
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        state.titleText = "添加充电站";
        state.buttonText = "确认添加";
        // 自动填写部分表单
        const currentChoiceAddress = loadAddressFromSession();
        if (currentChoiceAddress) {
          state.area = currentChoiceAddress.area;
          state.address = currentChoiceAddress.address;
          state.longitude = currentChoiceAddress.lng;
          state.latitude = currentChoiceAddress.lat;
        }
        const { contact_name, phone } = store.getters.agentInfo;
        state.support_contact = contact_name;
        state.support_phone = phone;
        state.loading = false;
      } else {
        state.id = id;
        state.titleText = "更新充电站";
        state.buttonText = "确认更新";
        loadForm(id);
      }
    });

    onUnmounted(() => {
      stationTypeColumn[0].defaultIndex = 0;
      buildingTypeColumn[0].defaultIndex = 0;
      stationStatusColumn[0].defaultIndex = 0;
    });

    return {
      state,
      buildingTypeColumn,
      stationTypeColumn,
      stationStatusColumn,
      chooseBuildingType,
      chooseStationType,
      chooseStatus,
      canSubmit,
      submitForm,
      fileList,
      beforeRead,
      afterRead,
    };
  },
};
</script>
